import {useEffect, useState} from 'react';
import {api} from 'services/api';
import {AxiosError, AxiosRequestConfig, AxiosResponse} from 'axios';

export function useApiLoader() {
  const [counter, setCounter] = useState<number>(0);

  useEffect(() => {
    const add = () => setCounter((counter) => counter + 1);

    const remove = () => setCounter((counter) => counter - 1);

    const onRequestFulfilled = (
      request: AxiosRequestConfig<unknown>
    ): AxiosRequestConfig<unknown> => {
      add();
      return request;
    };

    const onResponseFulfilled = (
      response: AxiosResponse<unknown, unknown>
    ): Promise<AxiosResponse<unknown, unknown>> => {
      remove();
      return Promise.resolve(response);
    };

    const onResponseRejected = (error: AxiosError) => {
      remove();
      return Promise.reject(error);
    };

    const request = api.interceptors.request.use(onRequestFulfilled);
    const response = api.interceptors.response.use(
      onResponseFulfilled,
      onResponseRejected
    );

    return () => {
      api.interceptors.request.eject(request);
      api.interceptors.response.eject(response);
    };
  }, []);

  return {isLoading: counter > 0};
}
