import React from 'react';
import {User} from 'context/user/types';

export type UserContextType = {
  user: User | null;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
  isUserLoading: boolean;
  setUserApprovalRequested: () => Promise<unknown>;
};

export const UserContext = React.createContext<UserContextType>({
  user: null,
  setUser: () => {},
  isUserLoading: false,
  setUserApprovalRequested: () => Promise.resolve(),
});
