// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const ENABLED_ENVIRONMENTS = ['production', 'preview'];
const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

if (ENABLED_ENVIRONMENTS.includes(process.env.NEXT_PUBLIC_VERCEL_ENV)) {
  Sentry.init({
    dsn:
      SENTRY_DSN ||
      'https://ee0f2e799cd94421886387ecbb6dfb0f@o509997.ingest.sentry.io/6446396',
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1.0,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  });
}
