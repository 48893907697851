import React, {useCallback, useEffect, useState} from 'react';
import {AppProps} from 'next/app';
import {Providers} from 'context';
import {AppLoader} from 'components/loader/app';
import {Toast} from 'components/toast';
import {AppInterceptor, setCSRFToken} from 'services/api';
import {AuthService} from 'app/auth/services';
import {FinishRegistrationObserver} from 'components/finishRegistrationObserver';
import * as Sentry from '@sentry/nextjs';
import {minutesToMilliseconds} from 'date-fns';

import 'styles/global.css';

const NeomApp = ({Component, pageProps}: AppProps) => {
  const [isCSRFLoaded, setIsCSRFLoaded] = useState(false);
  const [csrfError, setCSRFError] = useState<Error | null>(null);

  const loadCSRFToken = useCallback(async function () {
    const csrfResponse = await AuthService.apiGetCsrf();
    const csrfToken = csrfResponse?.headers['x-csrf-token'];
    if (!csrfToken) {
      const err = new Error('CSRF token is not found');
      setCSRFError(err);
      Sentry.captureException(err);
      return;
    }

    setCSRFToken(csrfToken);
    setIsCSRFLoaded(true);
  }, []);

  useEffect(() => {
    loadCSRFToken();
    const intervalId = setInterval(loadCSRFToken, minutesToMilliseconds(29));

    return () => {
      clearInterval(intervalId);

      setCSRFToken(null);
      setIsCSRFLoaded(false);
    };
  }, [loadCSRFToken]);

  return (
    <Providers>
      {renderAppOrCriticalErrorOrNull()}
      <AppLoader />
      <Toast />
      <FinishRegistrationObserver />
      <AppInterceptor />
    </Providers>
  );

  function renderAppOrCriticalErrorOrNull() {
    if (csrfError) return <div>Critical error. Please contact the support</div>;

    if (isCSRFLoaded) return <Component {...pageProps} />;

    if (!isCSRFLoaded) return null;

    return null;
  }
};

export default NeomApp;
