import React from 'react';
import {ToastContainer, Slide} from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

export function Toast() {
  return (
    <ToastContainer
      position="bottom-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      transition={Slide}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="dark"
      bodyClassName="text-base"
    />
  );
}
