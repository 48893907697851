import React, {useEffect} from 'react';
import {useApiLoader} from 'hooks/apiLoader';
import {Loader} from 'components/loader';

export function AppLoader() {
  const {isLoading} = useApiLoader();

  useEffect(() => {
    if (isLoading) {
      window.document.documentElement.style.overflow = 'hidden';
    } else {
      window.document.documentElement.style.overflow = 'visible';
    }
  }, [isLoading]);

  return isLoading ? <Loader /> : null;
}
