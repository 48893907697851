import React, {PropsWithChildren, useEffect, useState} from 'react';
import {UserContext} from 'context/user/context';
import {User} from 'context/user/types';
import {AuthService} from 'app/auth/services';

export function UserProvider(props: PropsWithChildren<{}>) {
  const {children} = props;

  const [user, setUser] = useState<User | null>(null);
  const [isUserLoading, setUserLoading] = useState(false);

  useEffect(() => {
    setUserLoading(true);
    (async function loadUser() {
      try {
        const userResponse = await AuthService.getUser();
        setUser(userResponse?.data || null);
      } finally {
        setUserLoading(false);
      }
    })();
  }, []);

  function setUserApprovalRequested() {
    setUser({...user, is_moderator_approval_requested: true} as User);
    return AuthService.apiSuccessRegistration();
  }

  return (
    <UserContext.Provider
      value={{user, isUserLoading, setUser, setUserApprovalRequested}}
    >
      {children}
    </UserContext.Provider>
  );
}
