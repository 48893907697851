import axios from 'axios';
import {BASE_URL} from 'services/api/constants';

export const api = axios.create({baseURL: BASE_URL, withCredentials: true});

export function setCSRFToken(token: string | null) {
  if (!token) {
    delete api.defaults.headers.common['x-csrf-token'];
  } else {
    api.defaults.headers.common['x-csrf-token'] = token;
  }
}
