import {useEffect} from 'react';
import {useUser} from 'context/user';
import {useRouter} from 'next/router';

export function FinishRegistrationObserver() {
  const {user} = useUser();

  const router = useRouter();

  useEffect(() => {
    if (!user) {
      return;
    }

    if (router?.pathname === '/create-account') {
      if (
        user?.is_moderator_approval_requested ||
        user?.is_moderator_approved
      ) {
        router.push('/');
      }

      return;
    }

    if (
      !user?.is_moderator_approval_requested &&
      !user?.is_moderator_approved
    ) {
      router.push('/create-account');
    }
  }, [user, router]);

  return null;
}
