import {api} from 'services/api';
import {ResetPasswordData, SignUpData, UserPutData} from 'app/auth/types';
import {ParsedUrlQuery} from 'querystring';

export const AuthService = {
  signUp: (data: SignUpData) => api.post('user/open', data),
  login: (data: FormData) => api.post('login/access-token', data),
  passwordRecovery: (email: string) => api.post(`password-recovery/${email}`),
  resetPassword: (data: ResetPasswordData) => api.post('reset-password', data),
  confirmEmail: (token: string) =>
    api.post('confirm-email', token, {
      headers: {'Content-Type': 'application/json'},
    }),
  apiSuccessRegistration: () => api.post('user/me/request-approval'),
  getUser: () => api.get('user/me'),
  apiPutUser: (data: UserPutData) => api.put('user/me', data),
  logOut: () => api.post('login/logout'),
  googleLogin: (redirectUrl: string) =>
    api.get<{follow_url: string}>('/social/google/login', {
      params: {redirect_uri: redirectUrl},
    }),
  googleAuth: (params: ParsedUrlQuery) =>
    api.get<{follow_url: string}>(`/social/google/auth`, {params: params}),
  linkedinLogin: (redirectUrl: string) =>
    api.get<{follow_url: string}>('/social/linkedin/login', {
      params: {redirect_uri: redirectUrl},
    }),
  linkedinAuth: (params: ParsedUrlQuery) =>
    api.get<{follow_url: string}>(`/social/linkedin/auth`, {params: params}),
  apiGetCsrf: () => api.get(`/_csrf`),
};
