import React from 'react';

import styles from 'components/loader/styles.module.css';

export function Loader() {
  const loaderClassNames = [
    styles.loader,
    'inline-block relative w-20 h-20',
  ].join(' ');

  return (
    <div className="fixed top-0 left-0 w-full h-screen flex items-center justify-center bg-[rgba(0,0,0,0.6)] z-50">
      <div className={loaderClassNames}>
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
}
