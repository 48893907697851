import {useCallback, useRef} from 'react';
import {useRouter} from 'next/router';
import {AxiosError} from 'axios';
import {api} from 'services/api';
import {toast} from 'react-toastify';
import {useUser} from 'context/user';

export function AppInterceptor() {
  const attached = useRef<boolean>(false);

  const {setUser} = useUser();
  const router = useRouter();

  const onResponseRejected = useCallback(
    (error: AxiosError) => {
      if (error?.response?.data) {
        const {detail} = error.response.data;

        if (error.response?.status !== 401 && detail) {
          toast.error(detail);
        }
      }

      if (error.response?.status === 401) {
        if (
          [
            '/moderator-invitation',
            '/register',
            '/sign-in',
            '/forgot-password',
            '/reset-password',
            '/contact-us',
            '/employee-invitation',
            '/privacy-policy',
          ].includes(router.pathname)
        )
          return;

        setUser(null);
        router.replace('/sign-in');
      }

      return Promise.reject(error);
    },
    [setUser, router]
  );

  const addInterceptorsToApi = useCallback(() => {
    const response = api.interceptors.response.use(
      undefined,
      onResponseRejected
    );

    return () => {
      api.interceptors.response.eject(response);
    };
  }, [onResponseRejected]);

  if (!attached.current) {
    addInterceptorsToApi();
    attached.current = true;
  }

  return null;
}
